import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, database, databaseRef, set } from './firebase'; 
import { push } from 'firebase/database'; 
import './Collection.css';

function Collection({ uid }) {
  const [playerCards, setPlayerCards] = useState([]);
  const [deck, setDeck] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null); 
  const [price, setPrice] = useState('');
  const [finalPrice, setFinalPrice] = useState('');
  const [error, setError] = useState(null); 
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false); 

  const MAX_DECK_SIZE = 20; // Максимальный размер колоды

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const playerDocRef = doc(db, 'users', uid);
        const playerDoc = await getDoc(playerDocRef);
        const playerData = playerDoc.exists() ? playerDoc.data() : {};

        if (playerData.cards && Array.isArray(playerData.cards)) {
          const playerCardDetails = await Promise.all(
            playerData.cards.map(async (card) => {
              const cardId = card.card_id;
              if (cardId) {
                const cardDocRef = doc(db, 'cards', cardId);
                const cardDoc = await getDoc(cardDocRef);
                const cardData = cardDoc.exists() ? cardDoc.data() : {};

                return {
                  id: cardId,
                  ...cardData,
                  random_value: card.random_value || 0, 
                };
              }
              return null;
            })
          );
          setPlayerCards(playerCardDetails.filter((card) => card !== null));
        } else {
          setPlayerCards([]);
        }

        if (playerData.deck && Array.isArray(playerData.deck)) {
          setDeck(playerData.deck);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных игрока:', error);
        setError('Не удалось загрузить данные игрока');
      }
    };

    fetchPlayerData();
  }, [uid]);

  const saveDeckToFirestore = async (updatedDeck) => {
    try {
      const playerDocRef = doc(db, 'users', uid);
      await updateDoc(playerDocRef, { deck: updatedDeck });
      console.log('Колода успешно сохранена в Firestore:', updatedDeck);
    } catch (error) {
      console.error('Ошибка при сохранении колоды в Firestore:', error);
      setError('Не удалось сохранить колоду.');
    }
  };

  const handleAddToDeck = (event, card) => {
    event.stopPropagation();
    if (deck.length < MAX_DECK_SIZE && !deck.some((deckCard) => deckCard.id === card.id)) {
      setDeck((prevDeck) => {
        const updatedDeck = [...prevDeck, card];
        saveDeckToFirestore(updatedDeck); 
        return updatedDeck;
      });
    } else {
      console.log('Карта уже в колоде или лимит достигнут.');
    }
  };
  
  const handleRemoveFromDeck = (card) => {
    setDeck((prevDeck) => {
      const updatedDeck = prevDeck.filter((deckCard) => deckCard.id !== card.id);
      saveDeckToFirestore(updatedDeck); 
      return updatedDeck;
    });
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseOverlay = () => {
    setSelectedCard(null);
    setIsSaleModalOpen(false);
  };

  return (
    <div className="collection-container">
      {error && <div className="error-message">{error}</div>}

      <h2>Колода</h2>
      <div className="deck-info">
        {deck.length} / {MAX_DECK_SIZE}
      </div>
      {deck.length === 0 ? (
        <div>Ваша колода пуста. Добавьте карты из инвентаря.</div>
      ) : (
        <div className="grid">
          {deck.map((card, index) => (
            <div key={`${card.id}-${index}`} className="card">
              <img src={card.image_url} alt={card.name} />
              <div>{card.name}</div>
              <div>Редкость: {card.random_value.toFixed(9)}</div>
              <button onClick={() => handleRemoveFromDeck(card)}>Удалить из колоды</button>
            </div>
          ))}
        </div>
      )}
      <h1>Ваш инвентарь</h1>
      {playerCards.length === 0 ? (
        <div className="empty-inventory">Ваш инвентарь пуст.</div>
      ) : (
        <div className="grid">
          {playerCards.map((card, index) => (
            <div key={`${card.id}-${index}`} className="card" onClick={() => handleCardClick(card)}>
              <img src={card.image_url} alt={card.name} />
              <div>{card.name}</div>
              <div>Редкость: {card.random_value.toFixed(9)}</div>
              <button onClick={(e) => handleAddToDeck(e, card)}>Добавить в колоду</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Collection;
