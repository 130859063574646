import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { db, database, get, databaseRef, update, onValue } from '../firebase'; // Подключение firebase.js
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { OpponentHandCards, HandCards, HandSection, BattleField, CardPile } from './HandCards'; 
import TurnManager from './TurnManager'; // Импортируем TurnManager

import './GamePage.css';

const GamePage = () => {
  const [searchParams] = useSearchParams();
  const [playerData, setPlayerData] = useState(null);
  const [opponentData, setOpponentData] = useState(null);
  const [deckInfo, setDeckInfo] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [pauseCountdown, setPauseCountdown] = useState(30);
  const [lobbyRef, setLobbyRef] = useState(null);
  const [gameOver, setGameOver] = useState(false);


  // State for player cards, opponent cards, and current player
  const [playerCards, setPlayerCards] = useState([]);
  const [opponentCards, setOpponentCards] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [uid, setUid] = useState(null); // Player's UID
  const [isMyTurn, setIsMyTurn] = useState(false); // Состояние для проверки, чей сейчас ход


  // Функция для обновления статуса лобби
  const updateLobbyStatus = async (lobbyId, status, countdown, winnerUid = null) => {
    const lobbyRef = databaseRef(database, `lobbies/${lobbyId}`);
    await update(lobbyRef, {
      status: status,
      countdown: countdown,
      winnerUid: winnerUid,
    });
  };

  const onCardDrop = (card) => {
    console.log('Card dropped:', card);
    // Обновить состояние карт после сброса
    setPlayerCards(prevCards => prevCards.filter(c => c.card_id !== card.card_id));
    // Добавить логику для отображения карты в BattleField
  };
  

  // Функция для получения размера колоды игрока
  const fetchDeckSize = async (uid) => {
    try {
      const deckCollectionRef = collection(db, `users/${uid}/deck`);
      const deckSnapshot = await getDocs(deckCollectionRef);

      if (!deckSnapshot.empty) {
        console.log(`Deck size for user ${uid}:`, deckSnapshot.size);
        return deckSnapshot.size;
      } else {
        console.warn(`No deck found for user ${uid}.`);
        return 0;
      }
    } catch (error) {
      console.error(`Error fetching deck size for user ${uid}:`, error);
      return 0;
    }
  };

  useEffect(() => {
    const start = searchParams.get('start'); // UID текущего игрока
    const lobby = searchParams.get('lobby'); // ID лобби

    console.log('Start UID:', start);
    console.log('Lobby ID:', lobby);

    if (start) {
      const playerRef = doc(db, 'users', start);
      getDoc(playerRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            console.log('Player data:', docSnapshot.data());
            setPlayerData(docSnapshot.data());
          } else {
            console.warn('Player document does not exist.');
          }
        })
        .catch((error) => console.error('Error fetching player data:', error));
    }

    if (lobby && start) {
      const playersRef = databaseRef(database, `lobbies/${lobby}/players`);
      get(playersRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const players = snapshot.val();
            console.log('Players in lobby:', players);

            const opponentUid = players.find((uid) => uid !== start);
            console.log('Opponent UID:', opponentUid);

            if (opponentUid) {
              const opponentRef = doc(db, 'users', opponentUid);
              getDoc(opponentRef)
                .then((docSnapshot) => {
                  if (docSnapshot.exists()) {
                    console.log('Opponent data:', docSnapshot.data());
                    setOpponentData(docSnapshot.data());
                  } else {
                    console.warn('Opponent document does not exist.');
                  }
                })
                .catch((error) => console.error('Error fetching opponent data:', error));
            } else {
              console.warn('Opponent UID not found in players list.');
            }
          } else {
            console.warn('Players list does not exist in the lobby.');
          }
        })
        .catch((error) => console.error('Error fetching players from lobby:', error));
    }

    // Set lobby reference
    if (lobby) {
      const lobbyReference = databaseRef(database, `lobbies/${lobby}`);
      setLobbyRef(lobbyReference);

      // Слушаем изменения в лобби
      onValue(lobbyReference, (snapshot) => {
        const lobbyData = snapshot.val();
        if (lobbyData) {
          // Если один из игроков покинул игру, засчитываем победу второму игроку
          if (lobbyData.players) {
            const remainingPlayers = Object.values(lobbyData.players).filter((uid) => uid !== null);
            if (remainingPlayers.length === 1) {
              setGameOver(true);
              setPauseCountdown(0); // Останавливаем таймер
              setIsPaused(false);

              // Найдем UID победителя (противника)
              const winnerUid = remainingPlayers[0];
              // Засчитываем победу для второго игрока
              updateLobbyStatus(lobby, "GameOver", 0, winnerUid);
              alert(`${winnerUid} wins!`);
            }
          }

          // Обновляем состояние игры в зависимости от данных лобби
          if (lobbyData.status === 'Pause') {
            setIsPaused(true);
            setPauseCountdown(lobbyData.countdown);
          } else {
            setIsPaused(false);
            setPauseCountdown(0);
          }
        }
      });
    }

    // Обработчик события выхода со страницы
    const handleBeforeUnload = () => {
      if (lobby && start) {
        // Обновляем статус лобби, что игрок покинул игру
        updateLobbyStatus(lobby, "GameOver", 0, start);  // Засчитываем победу второму игроку
      }
    };

    const handlePopState = () => {
      if (lobby && start) {
        // Обновляем статус лобби при переходе назад
        updateLobbyStatus(lobby, "GameOver", 0, start);  // Засчитываем победу второму игроку
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [searchParams]);

  useEffect(() => {
    const updateDeckInfo = async () => {
      if (playerData && opponentData) {
        console.log('Fetching deck sizes for players...');

        const playerDeckSize = playerData.deck?.length ?? await fetchDeckSize(playerData.uid);
        const opponentDeckSize = opponentData.deck?.length ?? await fetchDeckSize(opponentData.uid);

        console.log('Player Deck Size:', playerDeckSize);
        console.log('Opponent Deck Size:', opponentDeckSize);

        setDeckInfo({
          player: `Your Deck: ${playerDeckSize} cards`,
          opponent: `Opponent's Deck: ${opponentDeckSize} cards`,
        });

        const timeout = setTimeout(() => {
          setDeckInfo(null);
        }, 3000);

        return () => clearTimeout(timeout);
      }
    };

    updateDeckInfo();
  }, [playerData, opponentData]);

  // Start the pause timer and update lobby
  useEffect(() => {
    let timerInterval;
    if (isPaused && pauseCountdown > 0) {
      timerInterval = setInterval(() => {
        setPauseCountdown((prev) => prev - 1);
      }, 1000);

      // Update lobby status to "Pause" and set countdown
      if (lobbyRef) {
        updateLobbyStatus(searchParams.get('lobby'), "Pause", pauseCountdown);
      }
    } else if (pauseCountdown === 0) {
      setIsPaused(false);  // Resume game after countdown ends

      // Update lobby status to "Play" and reset countdown
      if (lobbyRef) {
        updateLobbyStatus(searchParams.get('lobby'), "Play", 0);
      }
    }

    return () => clearInterval(timerInterval);
  }, [isPaused, pauseCountdown, lobbyRef]);

  // Toggle pause state
  const handlePause = () => {
    setIsPaused(true);
    setPauseCountdown(30);  // Reset countdown to 30 seconds

    // Update lobby status to "Pause" and set countdown to 30
    if (lobbyRef) {
      updateLobbyStatus(searchParams.get('lobby'), "Pause", 30);
    }
  };

  return (
    <DndProvider backend={TouchBackend}>
      <div className="game-container">
        {/* Player info (top) */}
        <div className="player-info top">
          {opponentData ? (
            <img src={opponentData.avatar_url} alt="Opponent" className="avatar opponent-avatar" />
          ) : (
            <p>Loading opponent...</p>
          )}
          {deckInfo?.opponent && <div className="deck-info-overlay opponent">{deckInfo.opponent}</div>}
        </div>

        {/* Opponent's hand section */}
        <div className="opponent-hand-section">
          <OpponentHandCards cards={opponentData?.deck || []} uid={opponentData?.uid} />
        </div>

        {/* Gameplay area */}
        <div className="gameplay-area">
          {/* Battle Field */}
          <BattleField onCardDrop={onCardDrop} cards={playerCards} />
        </div>

        <TurnManager 
          currentPlayer={currentPlayer} 
          setCurrentPlayer={setCurrentPlayer} 
        />

        {/* Other elements, like for hand or battlefield */}
        <div className="hand-section">
          <HandCards cards={playerData?.deck || []} uid={playerData?.uid} />
        </div>

        {/* Player info (bottom) */}
        <div className="player-info bottom">
          {playerData ? (
            <img src={playerData.avatar_url} alt="Player" className="avatar player-avatar" />
          ) : (
            <p>Loading player...</p>
          )}
          {deckInfo?.player && <div className="deck-info-overlay player">{deckInfo.player}</div>}
        </div>

        {/* Pause Button */}
        <button
          className="pause-button"
          onClick={handlePause}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#DC5F00',
            color: 'white',
            borderRadius: '5px',
            zIndex: 1000,
          }}
        >
          Пауза
        </button>

        {/* Pause Overlay */}
        {isPaused && (
          <div
            className="pause-overlay"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '48px',
              fontWeight: 'bold',
              zIndex: 9999,
            }}
          >
            <p>ПАУЗА: {pauseCountdown}</p>
          </div>
        )}

        {/* Game Over Overlay */}
        {gameOver && (
          <div
            className="game-over-overlay"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontSize: '48px',
              fontWeight: 'bold',
              zIndex: 9999,
            }}
          >
            <p>Игра завершена</p>
          </div>
        )}

        {/* Добавляем кучи карт в углы */}
        <CardPile isOpponent={true} className="card-pile-top-left" />
        <CardPile isOpponent={false} className="card-pile-bottom-right" />
      </div>
    </DndProvider>
  );
};

export default GamePage;
