import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // Для получения параметра `start`
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase'; // Подключение Firebase конфигурации
import './Profile.css'; // Стили для страницы

const ProfilePage = () => {
  const [searchParams] = useSearchParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Получаем UID из параметра `start`
  const uid = searchParams.get('start');

  useEffect(() => {
    if (!uid) {
      setError('UID пользователя отсутствует');
      setLoading(false);
      return;
    }

    const fetchProfileData = async () => {
      try {
        const userRef = doc(db, 'users', uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          setProfileData(userSnapshot.data());
        } else {
          setError(`Пользователь с UID ${uid} не найден`);
        }
      } catch (err) {
        console.error('Ошибка загрузки данных пользователя:', err);
        setError('Ошибка загрузки данных');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [uid]);

  if (loading) {
    return <p>Загрузка профиля...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="profile-container">
      <div className="profile-avatar">
        <img src={profileData.avatar_url} alt="Avatar" />
      </div>
      <div className="profile-info">
        <h2>{profileData.nickname}</h2>
        <p className="uid">UID: {uid}</p>
      </div>
      <div className="profile-stats">
        <h3>Статистика:</h3>
        <p>Победы: {profileData.stats?.wins ?? 0}</p>
        <p>Поражения: {profileData.stats?.losses ?? 0}</p>
      </div>
    </div>
  );
};

export default ProfilePage;
