import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend'; // Используем TouchBackend для мобильных устройств
import BattleField from './components/Game/GamePage'; // Ваш компонент с drag-and-drop

import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import CollectionsIcon from '@mui/icons-material/Collections';
import StoreIcon from '@mui/icons-material/Store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './App.css'; // убедитесь, что у вас есть файл стилей для App

import Shop from './components/ShopPage';
import Collection from './components/CollectionPage';
import Profile from './components/ProfilePage';
import FightPage from './components/FightPage'; // Поменяйте имя компонента на FightPage
import Game from './components/Game/GamePage';

function App() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get('start'); // Извлекаем параметр start

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const webApp = window.Telegram.WebApp;

      webApp.setHeaderColor('#000000'); // Черный цвет заголовка
      webApp.setBackgroundColor('#333333'); // Темно-серый фон

      if (!webApp.isExpanded) {
        webApp.expand();
      }

      // Блокируем ориентацию и свайпы
      webApp.lockOrientation("portrait");  // Блокировка ориентации (переворот экрана)
      webApp.disableVerticalSwipes();      // Отключение вертикальных свайпов
    }
  }, []);

  // Проверяем, находимся ли мы на странице Game или Fight
  const isGamePage = location.pathname === '/Game';
  return (
    <div>
      <div className="game-version">v0.12.25</div>
      {/* Нижняя навигация */}
      {!isGamePage && (  // Скрываем навигацию на страницах Game и Fight
        <div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1000, height: 55 }}>
          <BottomNavigation showLabels>
            <BottomNavigationAction
              label="Shop"
              icon={<StoreIcon />}
              component={Link}
              to={`/shop?start=${uid}`}  // Correct usage of template literal
            />
            <BottomNavigationAction
              label="Collection"
              icon={<CollectionsIcon />}
              component={Link}
              to={`/collection?start=${uid}`}  // Correct usage of template literal
            />
            <BottomNavigationAction
              label="Fight"
              icon={<SportsEsportsIcon />}
              component={Link}
              to={`/fight?start=${uid}`}  // Correct usage of template literal
            />
            <BottomNavigationAction
              label="Profile"
              icon={<AccountCircleIcon />}
              component={Link}
              to={`/profile?start=${uid}`}  // Correct usage of template literal
            />
          </BottomNavigation>
        </div>
      )}

      {/* Маршруты с DndProvider */}
      <DndProvider backend={TouchBackend}>
        <Routes>
          <Route path="/shop" element={<Shop uid={uid} />} />
          <Route path="/collection" element={<Collection uid={uid} />} />
          <Route path="/profile" element={<Profile uid={uid} />} />
          <Route path="/fight" element={<FightPage uid={uid} />} />
          <Route path="/Game" element={<Game uid={uid} />} />
        </Routes>
      </DndProvider>
    </div>
  );
}

export default App;
