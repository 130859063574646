import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ref, update, onValue } from 'firebase/database';
import { database, firestore, db } from '../firebase'; // Ваш файл Firebase
import { doc, getDoc } from 'firebase/firestore';

const TurnManager = () => {
  const location = useLocation();
  const [currentPlayer, setCurrentPlayer] = useState(''); // Ник текущего игрока
  const [remainingTime, setRemainingTime] = useState(30);
  const [playerId, setPlayerId] = useState(''); // ID текущего игрока
  const [players, setPlayers] = useState([]); // Список игроков (массив uid)
  const [userId, setUserId] = useState(''); // UID текущего игрока
  const [lobbyId, setLobbyId] = useState(''); // ID лобби

  // Извлекаем параметры из URL
  const queryParams = new URLSearchParams(location.search);
  const start = queryParams.get('start'); // UID текущего игрока
  const lobby = queryParams.get('lobby'); // ID лобби

  useEffect(() => {
    console.log('Start:', start);
    console.log('Lobby:', lobby);

    if (start && lobby) {
      setUserId(start); // Устанавливаем userId
      setLobbyId(lobby); // Устанавливаем lobbyId
    } else {
      console.error('Ошибка: start или lobby параметры не переданы в URL');
    }
  }, [location.search]);

  useEffect(() => {
    // Проверьте, что lobbyId и userId не пустые
    console.log('lobbyId:', lobbyId, 'userId:', userId);

    if (!lobbyId || !userId) {
      console.error('Ошибка: lobbyId или userId не установлены');
      return;
    }

    // Подключаемся к Firebase для отслеживания хода игры
    const lobbyRef = ref(database, `lobbies/${lobbyId}`);
    const unsubscribe = onValue(lobbyRef, async (snapshot) => {
      const data = snapshot.val();

      if (data) {
        // Получаем список игроков (преобразуем объект в массив)
        const playersData = Object.values(data.players || {});
        setPlayers(playersData);
        // Если текущий игрок еще не выбран, случайным образом выбираем первого игрока
        if (!data.currentTurn?.playerId && playersData.length > 0) {
          const randomPlayerId = playersData[Math.floor(Math.random() * playersData.length)];
          const nickname = await fetchNickname(randomPlayerId);
          setPlayerId(randomPlayerId);
          setCurrentPlayer(nickname);

          // Устанавливаем первого игрока в качестве текущего в Firebase
          const turnRef = ref(database, `lobbies/${lobbyId}/currentTurn`);
          update(turnRef, {
            playerId: randomPlayerId,
            player: nickname,
            remainingTime: 30,
          }).catch((error) => console.error('Ошибка обновления текущего игрока:', error));
        } else if (data.currentTurn?.playerId) {
          // Если текущий игрок уже выбран, продолжаем показывать его
          setCurrentPlayer(data.currentTurn.player);
          setPlayerId(data.currentTurn.playerId);
          setRemainingTime(data.currentTurn.remainingTime || 30);
        }
      }
    });

    return () => unsubscribe();
  }, [lobbyId, userId]); // Эти параметры должны быть зависимыми

  useEffect(() => {
    // Таймер для обратного отсчета
    const timer = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1) {
          handleEndTurn(); // Автоматическое завершение хода
          console.log('Ход завершен, сброс времени');
          return 30;
        }
        const newTime = prev - 1;
        console.log('Оставшееся время:', newTime);

        // Обновляем время в Firebase
        const turnRef = ref(database, `lobbies/${lobbyId}/currentTurn`);
        update(turnRef, { remainingTime: newTime }).catch((error) =>
          console.error('Ошибка обновления времени:', error)
        );

        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer); // Очищаем таймер при размонтировании
  }, [playerId, lobbyId]);

  const fetchNickname = async (uid) => {
    try {
      const docRef = doc(db, 'users', uid); // Ссылка на пользователя в Firestore
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Никнейм найден в Firestore:', docSnap.data().nickname);
        return docSnap.data().nickname || 'Игрок';
      }
      console.log('Никнейм не найден, возвращаем "Игрок"');
      return 'Игрок';
    } catch (error) {
      console.error('Ошибка получения ника:', error);
      return 'Игрок';
    }
  };

  const handleEndTurn = async () => {
    if (!players || players.length === 0) {
      console.error('Ошибка: список игроков пустой или не определен.');
      return;
    }

    const currentIndex = players.indexOf(playerId);
    console.log('Текущий индекс игрока:', currentIndex);

    if (currentIndex === -1) {
      console.error('Ошибка: текущий игрок не найден в списке игроков.');
      return;
    }

    const nextIndex = (currentIndex + 1) % players.length; // Определяем индекс следующего игрока
    const nextPlayerId = players[nextIndex]; // ID следующего игрока
    console.log('ID следующего игрока:', nextPlayerId);

    if (!nextPlayerId) {
      console.error('Ошибка: ID следующего игрока не определен.');
      return;
    }

    const nickname = await fetchNickname(nextPlayerId); // Загружаем ник следующего игрока
    console.log('Ник следующего игрока:', nickname);

    // Обновляем данные в Firebase (с добавлением playerId и player)
    const turnRef = ref(database, `lobbies/${lobbyId}/currentTurn`);
    update(turnRef, {
      playerId: nextPlayerId,  // Сохраняем id текущего игрока
      player: nickname,        // Сохраняем ник текущего игрока
      remainingTime: 30,       // Сбрасываем таймер
    }).catch((error) => console.error('Ошибка обновления хода:', error));
  };

  // Проверяем, отображать ли кнопку завершения хода
  const canEndTurn = userId === playerId;

  return (
    <div style={styles.turnManager}>
      <p>Ход: {currentPlayer}</p>
      <p>Оставшееся время: {remainingTime} секунд</p>
      {canEndTurn && ( // Показываем кнопку только текущему игроку
        <button onClick={handleEndTurn} style={styles.endTurnButton}>
          Завершить ход
        </button>
      )}
    </div>
  );
};

// Стили для компонента
const styles = {
  turnManager: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '100000'
  },
  endTurnButton: {
    marginTop: '10px',
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: '100000'
  },
};

export default TurnManager;
