import { useState, useEffect } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import './HandCards.css';
import { getDatabase, ref, push, onValue } from 'firebase/database';
import { useSpring, animated } from 'react-spring';

// Функция для генерации случайных поворотов карт
const getRandomRotation = () => Math.random() * 25 - 12;
const getRandomOffset = () => Math.random();

// Функция для добавления карты на поле боя
const addCardToBattlefield = (card, lobbyId, userId) => {
  if (!lobbyId || !userId) {
    console.error('addCardToBattlefield: Missing lobbyId or userId');
    return;
  }

  const db = getDatabase();
  const cardRef = ref(db, `lobbies/${lobbyId}/fight/${userId}/${card.card_id}`);
  push(cardRef, {
    ...card,
    timestamp: Date.now(),
  });
  console.log('Card added to battlefield:', card);
};


// Компонент для кучки карт
const CardPile = ({ isOpponent, cardCount = 0 }) => {
  const pileProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { tension: 150, friction: 20 },
  });

  const cornerStyles = isOpponent
    ? { top: '0px', left: '10px' }
    : { bottom: '90px', right: '42px' };

  return (
    <animated.div
      className={`card-pile ${isOpponent ? 'opponent' : 'player'}`}
      style={{ ...cornerStyles, ...pileProps }}
    >
      {Array.from({ length: 7 }).map((_, index) => (
        <div
          key={index}
          className="card-pile-card"
          style={{
            transform: `rotate(${getRandomRotation()}deg) translate(${getRandomOffset()}px, ${getRandomOffset()}px)`,
          }}
        >
          <img src="/CARDB.jpg" alt="Card Back" />
        </div>
      ))}
      {!isOpponent && (
        <div className="pile-overlay">
          <img src="/pngegg.png" alt="Special Overlay" />
          <div className="pile-count">{cardCount}</div>
        </div>
      )}
    </animated.div>
  );
};

// Карточка игрока
const HandCard = ({ card, index, isOpponent, isPlayerTurn, playerId, currentPlayerId, userId, lobbyId }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { card, playerId, userId, lobbyId },
    canDrag: () => {
      // Убедитесь, что игрок может перемещать карты только в своей очереди
      return isPlayerTurn && playerId === currentPlayerId && lobbyId && userId;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const props = useSpring({
    to: { opacity: 1, transform: 'translate(0, 0) rotate(0deg)' },
    from: {
      opacity: 0,
      transform: isOpponent
        ? 'translate(-100px, -100px) rotate(-30deg)'
        : 'translate(100px, 100px) rotate(30deg)',
    },
    delay: 3000 + index * 200,
    config: { tension: 150, friction: 20 },
  });

  if (!card || !card.image_url || !card.name) {
    return <div>Invalid Card</div>;
  }

  return (
    <animated.div
      ref={drag}
      style={{
        ...props,
        cursor: isDragging ? 'grabbing' : 'pointer',
        opacity: isDragging ? 0.5 : 1,
      }}
      className={`player-card ${isDragging ? 'dragging' : ''}`}
      data-testid={`card-${card.name}`}
    >
      <img src={card.image_url} alt={card.name} />
      {!isOpponent && <p>{card.name}</p>}
    </animated.div>
  );
};


// Поле сражения
const BattleField = ({ onCardDrop, isPlayerTurn, currentPlayerId, lobbyId, userId }) => {
  const [battleCards, setBattleCards] = useState([]);

  useEffect(() => {
    if (!lobbyId || !userId) return;

    const db = getDatabase();
    const fightRef = ref(db, `lobbies/${lobbyId}/fight/${userId}`);

    const unsubscribe = onValue(fightRef, (snapshot) => {
      const cards = snapshot.val();
      const formattedCards = cards
        ? Object.values(cards).map((card, index) => ({
            ...card,
            id: index,
          }))
        : [];
      setBattleCards(formattedCards);
    });

    return () => unsubscribe();
  }, [lobbyId, userId]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'CARD',
    canDrop: (item) => {
      if (!isPlayerTurn || item.playerId !== currentPlayerId || !item.lobbyId || !item.userId) return false;
      return true;
    },
    drop: (item) => {
      onCardDrop(item.card);
      addCardToBattlefield(item.card, lobbyId, userId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const borderColor = isOver ? 'green' : canDrop ? 'orange' : 'transparent';
  const backgroundColor = isOver ? 'rgba(0, 255, 0, 0.1)' : 'transparent';

  return (
    <div
      ref={drop}
      className="battle-field"
      style={{
        backgroundColor,
        border: `2px dashed ${borderColor}`,
        padding: '10px',
        position: 'relative',
        minHeight: '200px',
        minWidth: '200px',
        touchAction: 'none',
      }}
    >
      <p>{isOver ? 'Drop here' : 'Drop your card here'}</p>
      <div className="battle-cards">
        {battleCards.map((card) => (
          <div key={card.id} className="battle-card">
            <img src={card.image_url} alt={card.name} />
          </div>
        ))}
      </div>
    </div>
  );
};


// Карты игрока
const HandCards = ({ cards, onCardDrop, isPlayerTurn, isOpponent, currentPlayerId, userId, lobbyId }) => (
  <div className="hand-cards">
    <div className="cards">
      {cards.map((card, index) => (
        <HandCard
          key={index}
          card={card}
          index={index}
          isOpponent={isOpponent}
          isPlayerTurn={isPlayerTurn}
          currentPlayerId={currentPlayerId}
          userId={userId}
          lobbyId={lobbyId}
        />
      ))}
    </div>
  </div>
);

const HandSection = ({ cards, onCardDrop, isPlayerTurn, currentPlayerId, userId, lobbyId }) => (
  <div className="hand-section">
    <HandCards
      cards={cards}
      onCardDrop={onCardDrop}
      isPlayerTurn={isPlayerTurn}
      currentPlayerId={currentPlayerId}
      userId={userId}
      lobbyId={lobbyId}
    />
  </div>
);

const OpponentHandCards = ({ cards }) => (
  <div className="opponent-hand-cards">
    <div className="opponent-cards">
      {cards.map((_, index) => (
        <HandCard key={index} card={{ image_url: '/CARDB.jpg', name: 'Card Back' }} index={index} isOpponent={true} />
      ))}
    </div>
  </div>
);

const GamePage = ({ cards, onCardDrop, isPlayerTurn, currentPlayerId, lobbyId, userId }) => (
  <DndProvider backend={TouchBackend}>
    <div className="game-page">
      <CardPile isOpponent={false} />
      <HandSection
        cards={cards}
        onCardDrop={onCardDrop}
        isPlayerTurn={isPlayerTurn}
        currentPlayerId={currentPlayerId}
        userId={userId}
        lobbyId={lobbyId}
      />
      <BattleField
        onCardDrop={onCardDrop}
        isPlayerTurn={isPlayerTurn}
        currentPlayerId={currentPlayerId}
        lobbyId={lobbyId}
        userId={userId}
      />
      <OpponentHandCards cards={cards} />
      <CardPile isOpponent={true} />
    </div>
  </DndProvider>
);

export { GamePage, OpponentHandCards, HandCards, HandSection, BattleField, CardPile };
